import StudentDocumentService from "@/services/StudentDocumentService";
import { EventBus } from '@/main'

import DoubleMajorApplicationService from "@/services/DoubleMajorApplicationService";
import downloadFile from '@/helpers/downloadFile'
import i18n from '@/plugins/i18n.js'
import Vue from "vue";
import qs from "qs"

export function downloadInsideDocument(name) {
    this.documentLoading = true
    if (name === "student_transcript") {
        StudentDocumentService.downloadTranscript(this.innerValue.student_program_id, this.$i18n.locale)
            .then(response => {
                let fileName = this.innerValue.student_program_id + '-' + this.$t('transcript') + '-' + this.$i18n.locale + '.pdf'
                EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
            }).finally(() => {
                this.documentLoading = false
            })
    } else if (name === "student_english_language_proficiency_document") {
        StudentDocumentService.downloadLanguageProficiency(this.innerValue.student_program_id, this.$i18n.locale)
            .then(response => {
                let fileName = this.innerValue.student_program_id + '-' + this.$t('file_name_language_proficiency') + '-' + this.$i18n.locale + '.pdf'
                EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
            })
            .catch(e => {
                this.showErrors(e, null, true)
            }).finally(() => {
                this.documentLoading = false
            })
    }
}

export async function exportExcel(params) {
    const config = {
        params,
        paramsSerializer: (params) => qs.stringify(params, { encode: false })
    };

    const response = await DoubleMajorApplicationService.excelExport(config)
        .catch(e => Vue.$toast.error(i18n.t('api.' + e.data.message)))

    if (response.status === 200) {
        downloadFile(response, `${i18n.t('double_major_applications')} ${i18n.t('report')}.xlsx`)
    }
    Vue.$toast.success(i18n.t('downloaded'));
}